import React from "react"
import {
  CheckboxLabelText,
  CheckIcon,
  CheckboxLabel,
  CheckLabelText,
  FakeInput,
  HelpText,
  HiddenInput,
} from "./checkbox.styles"
import Stack from "@ui/stack"

export type CheckboxProps = Omit<JSX.IntrinsicElements["input"], "type"> & {
  label: string
  help?: string
}

export const Checkbox = React.forwardRef(({ label, help, className, ...props }: CheckboxProps, ref) => {
  const ids = {
    label: `${props.name}-label`,
    help: help ? `${props.name}-help` : undefined,
  }
  return (
    <CheckboxLabel className={className}>
      <Stack gap="1">
        <CheckLabelText>
          <HiddenInput
            {...(props as any)}
            type="checkbox"
            aria-labelledby={ids.label}
            aria-describedby={ids.help}
            ref={ref}
          />
          <FakeInput>
            <CheckIcon />
          </FakeInput>
          <CheckboxLabelText id={ids.label} bold={!!help} aria-hidden>
            {label}
          </CheckboxLabelText>
        </CheckLabelText>
        {help && (
          <HelpText id={ids.help} aria-hidden>
            {help}
          </HelpText>
        )}
      </Stack>
    </CheckboxLabel>
  )
})

Checkbox.displayName = "Checkbox"
