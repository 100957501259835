import React from "react"
import { FieldRenderProps } from "react-final-form"
import { Checkbox } from "./checkbox"

export type CheckboxFieldProps = FieldRenderProps<string, HTMLInputElement>

export const CheckboxField = React.forwardRef(({ input, label, help, required, disabled }: CheckboxFieldProps, ref) => {
  return <Checkbox ref={ref} {...input} label={label} help={help} required={required} disabled={disabled}></Checkbox>
})

CheckboxField.displayName = "CheckboxField"
