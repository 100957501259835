import { FORM_ERROR, ValidationErrors } from "final-form"

export type GraphFieldError = {
  name: string
  error: string
}

export type GraphErrors = {
  fields?: (GraphFieldError | null)[] | null
  allFields?: string | null
}

export const graphToFinalFormErrors = (graphErrors: GraphErrors = {}): ValidationErrors => {
  const errors: ValidationErrors = {}

  graphErrors["fields"]
    ?.filter((p) => !!p)
    .forEach((field) => {
      const { name, error } = field || {}

      if (name === "base") {
        errors[FORM_ERROR] = error
      } else if (name && error) {
        errors[name] = error
      }
    })

  if (graphErrors["allFields"]) {
    errors[FORM_ERROR] = graphErrors["allFields"]
  }

  return errors
}
