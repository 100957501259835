import { FormApi } from "final-form"
import { FormProps as FinalFormProps, Form as FinalForm } from "react-final-form"
import { GraphErrors, graphToFinalFormErrors } from "./graph-errors"

type FormProps = FinalFormProps & {
  onSubmit(values: any, form: FormApi): Promise<GraphErrors | undefined | null | void>
}

export const Form = ({ onSubmit, ...props }: FormProps) => {
  return (
    <FinalForm
      onSubmit={async (values, form) => {
        const graphErrors = await onSubmit(values, form)

        const e = graphToFinalFormErrors(graphErrors || {})

        return e
      }}
      {...props}
    />
  )
}

export { Field } from "react-final-form"
