import styled from "styled-components"
import { Stack } from "@ui/stack"

export const Fieldset = styled(Stack).attrs({ gap: 5 })``

Fieldset.displayName = "Fieldset"

Fieldset.defaultProps = {
  width: "100%",
}
