import { FieldValidator } from "final-form"

type Options = {
  optional?: boolean
}

export const validates = <T>(...validators: FieldValidator<T>[]): FieldValidator<T> => {
  return (value, allValues: any) => {
    for (const validator of validators) {
      const error = validator(value, allValues)

      if (error) {
        return error
      }
    }
  }
}

export const required: FieldValidator<string | null> = (value) => {
  if (!value) return "Required"
}

export const matches = (otherField: string): FieldValidator<string> => {
  return (value, allValues: any) => {
    if (value !== allValues[otherField]) {
      return `Does not match ${otherField}`
    }
  }
}

export const minLength = (len: number, options: Options = {}) => {
  return (value: string | null) => {
    value = value ?? ""
    if (options.optional && !value) {
      return
    }
    if (value.length < len) {
      return `Too short. Must have at least ${len} characters.`
    }
  }
}

export const length = (len: number, options: Options = {}) => {
  return (value: string | null) => {
    value = value ?? ""
    if (options.optional && !value) {
      return
    }
    if (value.length > len) {
      return `Too long. Must have ${len} characters.`
    }
    if (value.length < len) {
      return `Too short. Must have ${len} characters.`
    }
  }
}

export const matchRegex = (regex: RegExp, message = "Invalid input.") => {
  return (value: string | null) => {
    value = value ?? ""

    if (value && !value.match(regex)) {
      return message
    }
  }
}

export const validPostalCode = matchRegex(/^[a-zA-z][0-9][a-zA-z]\s?[0-9][a-zA-z][0-9]$/)
export const validDate = matchRegex(
  /(?:19\d{2}|20\d{2})[-/.](?:0[1-9]|1[012])[-/.](?:0[1-9]|[12][0-9]|3[01])\b/,
  "YYYY/MM/DD"
)
