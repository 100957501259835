import React from "react"
import css from "@styled-system/css"
import styled from "styled-components"
import { variant } from "styled-system"

export const CheckboxLabel = styled.label`
  display: block;
  cursor: pointer;
`

export const HelpText = styled.div`
  ${css({
    paddingLeft: "34px",
    color: "gray.text",
  })}
`

export const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
`

/**
 * Regular checkbox inputs can't be styled. Creating a fake
 * input that shows the state is standard approach.
 */
export const FakeInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  ${css({
    borderWidth: 1,
    borderRadius: "small",
    borderStyle: "solid",
    borderColor: "gray.500",
  })}
`

export const CheckLabelText = styled.span`
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 16px;
  ${css({ color: "gray.text" })}
  input {
    cursor: pointer;
  }
  input:disabled {
    cursor: not-allowed;
  }
  input:disabled ~ * {
    cursor: not-allowed;
  }
  ${FakeInput} {
    cursor: pointer;
    margin-right: 12px;
    background-color: white;
  }
  input:checked + ${FakeInput} {
    ${css({ backgroundColor: "primary.300", borderColor: "gray.500" })}
  }
  input:focus + ${FakeInput} {
    box-shadow: 0 0 0 0.05em #fff, 0 0 0 3px rgba(0, 0, 0, 0.15);
  }
  input:disabled + ${FakeInput} {
    ${css({ borderColor: "gray.300" })}
  }
  input:disabled:checked + ${FakeInput} {
    ${css({ backgroundColor: "gray.300" })}
  }
`

export const CheckboxLabelText = styled.span<{ bold?: boolean }>`
  ${variant({
    prop: "bold",
    variants: {
      true: {
        color: "gray.text",
        fontWeight: "bold",
      },
    },
  })}
`

export const CheckIcon = () => (
  <svg role="presentation" width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2 6L10.0503 1.02531C10.5983 0.463296 11.5017 0.463295 12.0497 1.02531C12.5784 1.56756 12.5784 2.43244 12.0497 2.97469L6.632 8.53128C5.84706 9.33635 4.55294 9.33635 3.768 8.53128L0.95032 5.64135C0.421626 5.0991 0.421626 4.23423 0.95032 3.69198C1.49829 3.12996 2.40171 3.12996 2.94968 3.69198L5.2 6Z"
      fill="white"
    />
  </svg>
)
